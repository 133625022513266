import { useEffect, useRef, useState } from "react";
import "./QrStyles.css";
import QrScanner from "qr-scanner";
import Button from "@material-ui/core/Button";

interface IQrReader {
  isOpened: boolean;

  cancel();
}

const QrReader = (params: IQrReader) => {
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  const [scannedResult, setScannedResult] = useState<string | undefined>("");
  const [scannedError, setScannedError] = useState<string | undefined>("");

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    console.log(result);
    setScannedResult(result?.data);
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    console.log(err);
    setScannedError(JSON.stringify(err));
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      const askCameraPermission =
        async (): Promise<MediaStream | null> => await navigator.mediaDevices.getUserMedia({ video: true });

      let localstream: MediaStream | null;
      askCameraPermission()
        .then(response => {
          localstream = response;
        })
        .then(() => {
          localstream?.getTracks().forEach(track => {
            track.stop();
          });
        })
        .catch(error => {
          alert(error);
        });
    }
  }, [qrOn]);

  return (
    <div className="qr-wrapper">
      <div className="qr-reader">
        <video ref={ videoEl }></video>
        <p className="qr-text">Наведите камеру на QR-код для получения данных заказа</p>
        {/*{ scannedResult && (
          <p className="qr-result-text">
            Результат: { scannedResult }
          </p>
        ) }*/}
        {/*{ scannedError && (
          <p className="qr-result-text">
            Ошибка: { scannedError }
          </p>
        ) }*/}
        <Button className="qr-cancel-button" variant="outlined" color="primary"
                onClick={ () => params.cancel() }>Отмена</Button>
      </div>
    </div>
  );
};

export default QrReader;
