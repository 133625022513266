import React, { useState } from 'react';
import { gql, useLazyQuery, useSubscription } from '@apollo/client';
import Menu from "../menu/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import OrderListItem from "./OrderListItem";
import QrDialog from "../Qr/QrDialog";
import Button from "@material-ui/core/Button";
import QrReader from "../Qr/QrReader";

const FETCH_ALL_ORDERS = gql`
    query{
        currentOrders {
            id,
            acceptedForDelivery,
            orderData {
                customerContact,
                customerAddress,
                customerComment,
                externalOrderId,
                externalOrderAcceptDatetime,
                products {
                    name,
                    amount
                },
                payments{
                    type,
                    amount
                }
            }
        }
    }
`;

const ADD_SUBSCRIPTION = gql`
    subscription{
        orderAdd {
            id,
            acceptedForDelivery,
            orderData {
                customerContact,
                customerAddress,
                customerComment,
                externalOrderId,
                externalOrderAcceptDatetime,
                products {
                    name,
                    amount
                },
                payments{
                    type,
                    amount
                }
            }
        }
    }
`;

const DEL_SUBSCRIPTION = gql`
    subscription{
        orderDelete {
            id
        }
    }
`;

const COMMAND_SUBSCRIPTION = gql`
    subscription{
        commandHandler {
            command
        }
    }
`;

const WRITE_LOCATION = gql`
    mutation writeLocation($lon: Float!, $lat: Float!) {
        writeLocation(lon: $lon, lat: $lat) {
            id
        }
    }
`;


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    margin: theme.spacing(1),

  },

}));

const Orders = (props) => {
  const classes = useStyles();
  const [data, setData] = useState<Array<any>>([])
  const [active, setActive] = useState<number>(-1)

  const [loadQrOrder, setLoadQrOrder] = useState<string>("")

  const [qrScannerState, setQrScannerState] = useState<boolean>(false)

  const [loadOrders] = useLazyQuery(FETCH_ALL_ORDERS, {
    onCompleted: (data) => {
      setData(data.currentOrders)
    }
  });

  useSubscription(
    COMMAND_SUBSCRIPTION,
    {
      shouldResubscribe: true,
      onSubscriptionData: ({ subscriptionData, client }) => {
        if (subscriptionData.data.commandHandler.command == 1) {
          loadOrders();
        }
      },
    })

  useSubscription(
    ADD_SUBSCRIPTION,
    {
      shouldResubscribe: true,
      onSubscriptionData: ({ subscriptionData, client }) => {
        const current = subscriptionData.data.orderAdd
        const findRes = data.findIndex((c) => c.id == current.id)
        if (findRes == -1) {
          setData([current, ...data])
        }
      },
    })

  useSubscription(
    DEL_SUBSCRIPTION,
    {
      shouldResubscribe: true,
      onSubscriptionData: ({ subscriptionData, client }) => {
        setData(data.filter((c) => c.id != subscriptionData.data.orderDelete.id))
      },
    })

  return (
    <React.Fragment>
      <Menu title={ 'Мои задачи' } />
      <CssBaseline />
      <QrDialog externalOrderId={ loadQrOrder } cancel={ () => setLoadQrOrder("") } />
      {/*<Box textAlign='center' m={ 3 }>
        <Button variant="contained" color="primary" onClick={ () => setQrScannerState(true) }>
          Сканировать QR-код
        </Button>
      </Box>*/}
      <div className={ classes.root }>
        { data.length > 0 ?
          data.map((order) => (
            <OrderListItem key={ order.id } data={ order } expanded={ active } toggle={ (e: number) => {
              setActive(active == e ? -1 : e)
            } } showQr={ (id: string) => setLoadQrOrder(id) } />
          ))
          :
          <Box style={ {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          } }>
            Ожидайте новых задач от администратора
          </Box>
        }
      </div>
      { qrScannerState && <QrReader isOpened={ qrScannerState } cancel={ () => setQrScannerState(false) } /> }
    </React.Fragment>
  );
}

export default Orders;
